import React from "react";
import { Link } from "react-router-dom";
import * as api from '../../services/api'

import { BeatLoader } from 'react-spinners';
import { css } from '@emotion/core';
// reactstrap components
import {
  Button,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  Input
} from "reactstrap";
import ApurasulPageHeader from "components/Headers/ApurasulPageHeader.js";


const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

function NoticiasPage(props) {

  const [noticias, setNoticias] = React.useState([]);
  const [formulario, setFormulario] = React.useState({ qtd: 10 });
  const [loading, setLoaging] = React.useState(false);

  const postFormulario = () => {
    let descricao = ""
    if (formulario.descricao) {
      descricao = formulario.descricao
    }
    const qtd = formulario.qtd
    api.getNoticiasCategoria(descricao, qtd)
      .then((res) => {
        setNoticias(res)
        setLoaging(false)
      })
    setLoaging(true)
  }

  document.documentElement.classList.remove("nav-open");

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    api.getNoticiasCategoria('', 10)
      .then((res) => {
        setNoticias(res)
      })
    setFormulario(Object.assign(formulario, { qtd: 10 }))


    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  }, []);
  return (
    <>
      <ApurasulPageHeader />
      <div className="section javascript-components" style={{ padding: "5px 0" }}>
        <Container>
          <div className="contact-form">
            <Row>
              <Col md="4">
                <label>Descrição</label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="nc-icon nc-tag-content" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Descrição"
                    type="text"
                    onChange={(e) => {
                      setFormulario(Object.assign(formulario, { descricao: e.target.value }))
                    }}
                  />
                </InputGroup>
              </Col>
              <Col md="2">
                <label>Quantidade</label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="nc-icon nc-ruler-pencil" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Qtd." type="number"
                    value={formulario.qtd}
                    onChange={(e) => {
                      setFormulario({ qtd: e.target.value })
                    }}

                  />
                </InputGroup>
              </Col>
              <Col md="4">
                <label>Categoria</label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="nc-icon nc-tile-56" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Departamento" type="select"
                    onChange={(e) => {
                      setFormulario(Object.assign(formulario, { categoria: e.target.value }))
                    }}
                  >
                    <option value="Todas">Todas</option>
                    {
                      noticias.map((item, i) => {
                        return <option key={i} value={item.id}>{item.descricao}</option>
                      })
                    }

                  </Input>
                </InputGroup>
              </Col>
              <Col md="2">
                <label> &nbsp; </label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="nc-icon nc-zoom-split" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Qtd." type="button"
                    value="Pesquisar"
                    onClick={() => postFormulario()}
                  />
                </InputGroup>
              </Col>

            </Row>
            <div className="contact-form text-center">
              <BeatLoader
                css={override}
                sizeUnit={"px"}
                size={20}
                color={'#f5593d'}
                loading={loading}
              />
            </div>
          </div>
          <Row>
            {
              noticias.map((item, i) => {
                if (formulario.categoria === undefined || formulario.categoria === item.descricao || formulario.categoria === "Todas") {
                  return <Col md="6" key={i}>
                    <div className="title">
                      <h3>{item.descricao}</h3>
                    </div>
                    {
                      item.dados.map((item, i) => {
                        return <div key={i} style={{ margin: "5px" }}>
                          <Link to={`/noticia/${item.chave}`}>
                            <Button
                              className="btn-round mr-1"
                              color="danger"
                              id={`tooltip${item.id}`}
                              outline

                              onMouseOver={e => e.target.focus()}
                            >
                              {item.titulo} <br />
                              {item.datahora}
                            </Button>
                          </Link>
                          <UncontrolledPopover
                            placement="top"
                            target={`tooltip${item.id}`}
                            trigger="focus"
                          >
                            <PopoverHeader>Assunto</PopoverHeader>
                            <PopoverBody>
                              {item.subtitulo}
                            </PopoverBody>
                          </UncontrolledPopover>
                        </div>

                      })
                    }

                  </Col>
                } else return null
              })
            }






            <br />

          </Row>
        </Container>
      </div>{" "}
    </>
  );
}

export default NoticiasPage;
