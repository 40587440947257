import React from "react";
// reactstrap components
import * as api from '../../services/api'
import { css } from '@emotion/core';
import { BeatLoader } from 'react-spinners';
import ApurasulPageHeader from "components/Headers/ApurasulPageHeader.js";

import {
  Container,
  Row,
  Col,
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  UncontrolledTooltip
} from "reactstrap";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;



function ContatoPage() {

  document.documentElement.classList.remove("nav-open");
  const [departamentos, setDepartamentos] = React.useState([]);
  const [formulario, setFormulario] = React.useState({});
  const [mensagem, setMensagem] = React.useState('');

  const [loading, setLoaging] = React.useState(false);

  const postFormulario = () => {
    setMensagem("Enviando mensagem...")
    setLoaging(true)
    api.postContato(formulario)
      .then(resultado => {
        setLoaging(false)
        setMensagem(resultado)
        window.scrollTo(0, 0);
      })
  }

  React.useEffect(() => {
    api.getDepartamentos()
      .then((result) => {
        setDepartamentos(result)
      })

    document.body.classList.add("landing-page");

    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  }, []);
  return (
    <>
      <ApurasulPageHeader />
      <div className="section landing-section">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h2 className="text-center">Contate-nos através do formulário abaixo:</h2>
              <h5 className="text-center">{mensagem}</h5>
              <div className="contact-form text-center">
                <BeatLoader
                  css={override}
                  sizeUnit={"px"}
                  size={20}
                  color={'#f5593d'}
                  loading={loading}
                />
              </div>
              {!loading ?
                <div className="contact-form">
                  <Row>
                    <Col md="6">
                      <label>Nome</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Nome"
                          type="text"
                          onChange={(e) => {
                            setFormulario(Object.assign(formulario, { nome: e.target.value }))
                          }}
                        />
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <label>E-mail</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="E-mail" type="email"
                          onChange={(e) => {
                            setFormulario(Object.assign(formulario, { email: e.target.value }))
                          }}

                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <label>Assunto</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Assunto"
                          type="text"
                          onChange={(e) => {
                            setFormulario(Object.assign(formulario, { assunto: e.target.value }))
                          }}
                        />
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <label>Departamento</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-badge" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Departamento" type="select"
                          onChange={(e) => {
                            setFormulario(Object.assign(formulario, { departamentoid: e.target.value }))
                          }}
                        >
                          {
                            departamentos.map((item, i) => {
                              return <option key={i} value={item.id}>{item.descricao}</option>
                            })
                          }

                        </Input>
                      </InputGroup>
                    </Col>
                  </Row>
                  <label>Mensagem</label>
                  <Input
                    placeholder="Digite aqui sua mensagem..."
                    type="textarea"
                    rows="4"
                    onChange={(e) => {
                      setFormulario(Object.assign(formulario, { mensagem: e.target.value }))
                    }}
                  />
                  <Row>
                    <Col className="ml-auto mr-auto text-center" md="12">
                      <Button onClick={() => {
                        postFormulario()
                      }} className="btn-fill" color="danger" size="lg">
                        Enviar Mensagem a Apurasul
                    </Button>
                    </Col>
                  </Row>
                </div>
                : null
              }
            </Col>
          </Row>
          <Row className="justify-content-md-center sharing-area text-center">
            <Col className="text-center" lg="8" md="12">
              <Button
                className="twitter-sharrre btn-round"
                color="google-bg"
                href="#"
                id="tooltip3373767"
                onClick={e => e.preventDefault()}
              >
                <i className="fa fa-map-marker" /> Carazinho - RS, Brasil
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip3373767">
                Endereço!
              </UncontrolledTooltip>
              <Button
                className="linkedin-sharrre btn-round  ml-2"
                color="google-bg"
                href="#"
                id="tooltip840791273"
                onClick={e => e.preventDefault()}
              >
                <img
                  alt="..."
                  width="15"
                  src={require("assets/img/whats.png")}
                />
                 &nbsp;(54) 9 8449-9277
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip840791273">
                Telefone!
              </UncontrolledTooltip>
              <Button
                className="facebook-sharrre btn-round ml-2"
                color="google-bg"
                href="#"
                id="tooltip68961360"
                onClick={e => e.preventDefault()}
              >
                <i className="fa fa-at" /> apurasul@apurasul.com.br
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip68961360">
                E-mail!
              </UncontrolledTooltip>
            </Col>
          </Row>
        </Container>
      </div>


    </>
  );
}

export default ContatoPage;
