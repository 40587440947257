import React from "react";
import * as api from '../../services/api'
import { Link } from 'react-router-dom'

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";


import LandingPageHeader from "components/Headers/LandingPageHeader.js";

function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  const [noticias, setNoticias] = React.useState([]);
  const [secoes, setSecoes] = React.useState([]);


  React.useEffect(() => {
    document.body.classList.add("profile-page");

    api.getNoticiasImagem('', 3)
      .then((res) => {
        setNoticias(res)
      })

    api.getSecoes()
      .then((result) => setSecoes(result))



    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  }, []);
  return (
    <>
      <LandingPageHeader />
      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">Conheça um pouco mais sobre a Apurasul</h2>
              </Col>
            </Row>
            <br />
            <Row className="text-center">
              {
                secoes.map((item, i) => {
                  if (item.funcao === "Serviços") {
                    return <Col md="3" key={i}>
                      <div className="info">
                        <div className="icon icon-danger">
                          <i className="nc-icon nc-bullet-list-67" />
                        </div>
                        <div className="description">
                          <h4 className="info-title">{item.descricaomenu}</h4>
                          <p className="description">
                            {item.titulo}
                          </p>
                          <Link to={`/secao/${item.chave}`}>
                            <span className="info-title icon-danger"> Saiba Mais</span>
                          </Link>

                        </div>
                      </div>
                    </Col>
                  } else return null
                })
              }
            </Row>
          </Container>
        </div>
        <div className="section section-dark text-center">
          <Container>
            <h2 className="title">Últimas Notícias</h2>
            <Row>
              {
                noticias.map((item, i) => {
                  return <Col md="4" key={i}>
                    <Card className="card-plain">
                      <div className="card-avatar">
                        <Link to={{ pathname: `/noticia/${item.chave}` }}>
                          {item.midia ? <img
                            alt="..."
                            src={`${item.midia}`}
                          />
                            : null
                          }
                        </Link>
                      </div>
                      <CardBody>
                        <Link to={{ pathname: `/noticia/${item.chave}` }}>
                          <div className="author">
                            <CardTitle tag="h4">{item.titulo}</CardTitle>
                            <h6 className="card-category">{item.categoriadescricao}</h6>
                          </div>
                        </Link>
                        <p className="card-description text-center">
                          {item.datahora}
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                })
              }
              <Col className="text-center" lg="12" md="12">
                <Link to={`/noticias`}>
                  <Button
                    className="btn-round mr-1"
                    color="danger"
                    outline
                  > + Notícias
                          </Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPage;
