import React from "react";
import ImageGallery from 'react-image-gallery';
import * as api from '../../services/api'

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";
import ApurasulPageHeader from "components/Headers/ApurasulPageHeader.js";


function SecaoPage(props) {
  const [secao, setSecao] = React.useState([]);
  const [midias, setMidias] = React.useState([]);

  document.documentElement.classList.remove("nav-open");

  React.useEffect(() => {
    let chave = props.match.params.chave;

    document.body.classList.add("landing-page");

    api.getSecao(chave)
      .then((result) => {
        setSecao(result)
        setMidias(result.galeria.midias)
      })


    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  }, [props.match.params.chave]);
  return (
    <>
      <ApurasulPageHeader />
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <div className="name">
              <h2 className="title">
                {secao.titulo}<br />
              </h2>
              <h6 className="description">{secao.descricaomenu}</h6>
            </div>
          </div>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <div dangerouslySetInnerHTML={{ __html: secao.conteudo }} />
              <br />
            </Col>
          </Row>

          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              {midias.length > 0
                ? <ImageGallery items={midias} />
                : null
              }
            </Col>
          </Row>

        </Container>
      </div>
    </>
  );
}

export default SecaoPage;
