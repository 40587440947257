import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
// pages
import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons.js";
import LandingPage from "views/examples/LandingPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import RegisterPage from "views/examples/RegisterPage.js";
import SecaoPage from "views/examples/SecaoPage";
import NoticiaPage from "views/examples/NoticiaPage";

import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import ScrollToTop from 'react-router-scroll-top'
import ContatoPage from "views/examples/ContatoPage";
import LinksPage from "views/examples/LinksPage";
import NoticiasPage from "views/examples/NoticiasPage";

// others

ReactDOM.render(
  <HashRouter >
    <ScrollToTop>
      <ExamplesNavbar />
      <Switch>
        <Route path="/home" render={props => <LandingPage {...props} />} />
        <Route path="/secao/:chave" render={props => <SecaoPage {...props} />} />
        <Route path="/noticia/:chave" render={props => <NoticiaPage {...props} />} />
        <Route path="/contato" render={props => <ContatoPage {...props} />} />
        <Route path="/links" render={props => <LinksPage {...props} />} />
        <Route path="/noticias" render={props => <NoticiasPage {...props} />} />

        <Route
          path="/nucleo-icons"
          render={props => <NucleoIcons {...props} />}
        />
        
        {/* A JSX comment 

        <Route path="/index" render={props => <Index {...props} />} />
        <Route
          path="/nucleo-icons"
          render={props => <NucleoIcons {...props} />}
        />
        <Route
          path="/landing-page"
          render={props => <LandingPage {...props} />}
        />
        <Route
          path="/profile-page"
          render={props => <ProfilePage {...props} />}
        />
        <Route
          path="/register-page"
          render={props => <RegisterPage {...props} />}
        />
        */}
        <Redirect to="/home" />
      </Switch>
    </ScrollToTop>
    <DemoFooter />
  </HashRouter >,
  document.getElementById("root")
);
