import axios from 'axios';

const api = "https://dustin.mootec.com.br/apurasulservice"


const ordenaLinks = (dados) => {
    let lista = dados.map((item, i) => {
        return { descricao: item.categoriadescricao, dados: [] }
    })

    let uniqueAddresses = Array.from(new Set(lista.map(a => a.descricao)))
        .map(descricao => {
            return lista.find(a => a.descricao === descricao)
        })

    dados.map((item, i) => {
        uniqueAddresses[uniqueAddresses.findIndex(dado => dado.descricao === item.categoriadescricao)]
            .dados.push(item)
    })

    return uniqueAddresses.sort((a, b) => (a.categoria > b.categoria) ? 1 : -1)
}

const ordenaNoticias = (dados) => {
    let lista = dados.map((item, i) => {
        return { descricao: item.categoriadescricao, dados: [] }
    })

    let uniqueAddresses = Array.from(new Set(lista.map(a => a.descricao)))
        .map(descricao => {
            return lista.find(a => a.descricao === descricao)
        })

    dados.map((item, i) => {
        uniqueAddresses[uniqueAddresses.findIndex(dado => dado.descricao === item.categoriadescricao)]
            .dados.push(item)
    })

    return uniqueAddresses.sort((a, b) => (a.categoria > b.categoria) ? 1 : -1)
}


export const getLinks = (param) => {
    const apiService = "/homeservice/obterlinksuteis"
    return new Promise((resolve, reject) => {
        axios.get(`${api}${apiService}`)
            .then((res) => {
                resolve(ordenaLinks(res.data['data']))
            })
            .catch((error) => {
                reject(error)
            })
    });
}

export const getDepartamentos = (param) => {
    const apiService = "/homeservice/obterdepartamentos"
    return new Promise((resolve, reject) => {
        axios.get(`${api}${apiService}`)
            .then((res) => {
                resolve(res.data['data'])
            })
            .catch((error) => {
                reject(error)
            })
    });
}


export const getSecoes = (param) => {
    const apiService = "/homeservice/obtersecoeshome/''"
    return new Promise((resolve, reject) => {
        axios.get(`${api}${apiService}`)
            .then((res) => {
                //console.log(res.data['data'])
                resolve(res.data['data'])
            })
            .catch((error) => {
                reject(error)
            })
    });
}

export const getSecao = (param) => {
    const apiService = `/homeservice/obtersecaohome/${param}`
    return new Promise((resolve, reject) => {
        axios.get(`${api}${apiService}`)
            .then((res) => {
                let secao = res.data['data']
                const dados = secao.galeria.midias.map(async (item, key) => {
                    let midia = ''
                    await getMidia(item.chave)
                        .then((midiaRet) => {
                            midia = midiaRet
                        })
                    return Object.assign(secao.galeria.midias[key], { original: midia, thumbnail: midia })
                })

                Promise.all(dados).then(values => resolve(values))

                resolve(res.data['data'])
            })
            .catch((error) => {
                reject(error)
            })
    });
}

export const getNoticia = (param) => {
    const apiService = `/homeservice/obternoticia/${param}`
    return new Promise((resolve, reject) => {
        axios.get(`${api}${apiService}`)
            .then((res) => {
                let noticia = res.data['data']
                noticia.galeria.midias.map(async (item, key) => {
                    let midia = ''
                    await getMidia(item.chave)
                        .then((midiaRet) => {
                            midia = midiaRet
                        })
                    return Object.assign(noticia.galeria.midias[key], { original: midia, thumbnail: midia })
                })

                //Promise.all(dados).then(values => resolve(values))

                resolve(res.data['data'])
            })
            .catch((error) => {
                reject(error)
            })
    });
}

export const getNoticiasCategoria = (descricao, limite) => {
    return new Promise((resolve, reject) => {
        getNoticias(descricao, limite)
            .then((res) => {
                resolve(ordenaNoticias(res))
            })
    })

}

export const getNoticias = (descricao, limite) => {
    const apiService = `/homeservice/obternoticias/'${descricao}'/${limite}`
    return new Promise((resolve, reject) => {
        axios.get(`${api}${apiService}`)
            .then((res) => {
                resolve(res.data['data']);
            })
            .catch((error) => {
                reject(error)
            })
    });
}

export const getNoticiasImagem = (descricao, limite) => {
    return new Promise((resolve, reject) => {
        getNoticias(descricao, limite)
            .then((res) => {

                const dados = res.map(async (item, key) => {
                    let midia = ''
                    await getMidiaPrincipal(item.galeriachave)
                        .then((midiaRet) => {
                            midia = midiaRet
                        })
                    return Object.assign(res[key], { midia })
                })

                Promise.all(dados).then(values => resolve(values))
            })
    });

}

export const getMidiaPrincipal = (chave) => {
    const apiService = `/homeservice/obtergaleria/${chave}`
    return new Promise((resolve, reject) => {
        axios.get(`${api}${apiService}`)
            .then((res) => {
                if (res.data['data'].midias.length > 0) {
                    axios.get(`${api}/galeriaservice/obtermidia/${res.data['data'].midias[0].chave}`, {
                        responseType: 'arraybuffer'
                    }).then((response) => {
                        //console.log(response.data)
                        const base64 = btoa(
                            new Uint8Array(response.data).reduce(
                                (data, byte) => data + String.fromCharCode(byte),
                                '',
                            ),
                        );
                        resolve(`data:image/png;base64,${base64}`);
                    })
                }
                else {
                    resolve(null);
                }

            })
            .catch((error) => {
                reject(error)
            })
    });
}

export const getMidia = (chave) => {
    return new Promise((resolve, reject) => {

        axios.get(`${api}/galeriaservice/obtermidia/${chave}`, {
            responseType: 'arraybuffer'
        }).then((response) => {
            //console.log(response.data)
            const base64 = btoa(
                new Uint8Array(response.data).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    '',
                ),
            );
            resolve(`data:image/png;base64,${base64}`);
        })


    });
}

export const postContato = (formulario) => {
    const apiService = `/homeservice/contato`
    return new Promise((resolve, reject) => {
        axios.post(`${api}${apiService}`, formulario)
            .then((res) => {
                resolve(res.data['message']);
            })
            .catch((error) => {
                console.log("Erro " + error)
                reject(error)
            })
    });
}