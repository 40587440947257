import React from "react";
import * as api from '../../services/api'


// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover
} from "reactstrap";
import ApurasulPageHeader from "components/Headers/ApurasulPageHeader.js";



function LinksPage(props) {

  const [links, setLinks] = React.useState([]);
  document.documentElement.classList.remove("nav-open");

  React.useEffect(() => {
    document.body.classList.add("landing-page");

    api.getLinks()
      .then((res) => {
        setLinks(res)
      })



    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  }, []);
  return (
    <>
      <ApurasulPageHeader />
      <div className="section javascript-components">
        <Container>
          <div className="title">
            <h2>Links Úteis / Ferramentas</h2>
          </div>
          <Row id="modals">
            {
              links.map((item, i) => {
                return <Col md="6" key={i}>
                  <div className="title">
                    <h3>{item.descricao}</h3>
                  </div>
                  {
                    item.dados.map((item, i) => {
                      return <div key={i} style={{margin: "5px"}}><Button
                        className="btn-round mr-1"
                        color="danger"
                        id={`tooltip${item.id}`}
                        outline
                        href={item.url}
                        target="_blank"
                        onMouseOver={e => e.target.focus()}
                      >
                        {item.descricao}
                      </Button>
                        <UncontrolledPopover
                          placement="top"
                          target={`tooltip${item.id}`}
                          trigger="focus"
                        >
                          <PopoverHeader>Link</PopoverHeader>
                          <PopoverBody>
                            {item.url}
                          </PopoverBody>
                        </UncontrolledPopover>
                      </div>

                    })
                  }

                </Col>
              })
            }



            <br />

          </Row>
        </Container>
      </div>{" "}
    </>
  );
}

export default LinksPage;
