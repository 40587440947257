
import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
import * as api from '../../services/api'

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container
} from "reactstrap";

function ExamplesNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [logo, setLogo] = React.useState("logowhite.png");
  const [secoes, setSecoes] = React.useState([]);


  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {

    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 149 ||
        document.body.scrollTop > 149
      ) {
        setNavbarColor("");
        setLogo("logoblack.png")
      } else if (
        document.documentElement.scrollTop < 150 ||
        document.body.scrollTop < 150
      ) {
        setNavbarColor("navbar-transparent");
        setLogo("logowhite.png")
      }
    };

    window.addEventListener("scroll", updateNavbarColor);
    api.getSecoes()
      .then((result) => {
        setSecoes(result)
      
      })

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);

    };
  }, []);
  return (
    <Navbar
      className={classnames("fixed-top", navbarColor)}
      color-on-scroll="300"
      expand="lg"
    >
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            to="/home"
            title="Coded by Creative Tim"
            tag={Link}
          >
            <img
              alt="..."
              src={require(`assets/img/${logo}`)}
              width="250"
            />

          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              <NavLink to="/" tag={Link}>
                <i className="nc-icon nc-tv-2" /> CAPA
              </NavLink>
            </NavItem>
            {
              secoes.map((item, i) => {
                if (item.funcao === "Principal") {
                  return <NavItem key={i}>
                    <NavLink to={`/secao/${item.chave}`} tag={Link}>
                      <i className="nc-icon nc-bullet-list-67" /> {item.descricaomenu}
                    </NavLink>
                  </NavItem>
                } else return null
              })
            }
            <NavItem>
              <NavLink to="/links" tag={Link}>
                <i className="fa fa-external-link-square " /> LINKS ÚTEIS
              </NavLink>

            </NavItem>
            <NavItem>
              <NavLink to="/contato" tag={Link}>
                <i className="nc-icon nc-send" /> CONTATO
              </NavLink>

            </NavItem>

          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default ExamplesNavbar;
