import React from "react";
import ImageGallery from 'react-image-gallery';
import * as api from '../../services/api'
import { Link } from "react-router-dom";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Button
} from "reactstrap";
import ApurasulPageHeader from "components/Headers/ApurasulPageHeader.js";


function NoticiaPage(props) {

  const [noticia, setNoticia] = React.useState([]);
  const [midias, setMidias] = React.useState([]);


  document.documentElement.classList.remove("nav-open");

  React.useEffect(() => {
    let chave = props.match.params.chave;

    document.body.classList.add("landing-page");

    api.getNoticia(chave)
      .then((result) => {
        //console.log(result)
        setNoticia(result)
        setMidias(result.galeria.midias)
      })


    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  }, [props.match.params.chave]);
  return (
    <>
      <ApurasulPageHeader />
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <div className="name">
              <h2 className="title">
                {noticia.titulo}<br />
              </h2>
              <h6 className="description">{noticia.subtitulo}</h6>
              <hr/>
            </div>
          </div>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <label className="label label-default">{noticia.categoriadescricao}</label>
              <label className="label label-default" style={{float: 'right'}}> {noticia.datahora}</label>
              
              <br/><br/>

              <div dangerouslySetInnerHTML={{ __html: noticia.conteudo }} />
              <br />
            </Col>
          </Row>

          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              {midias.length > 0
                ? <ImageGallery items={midias} />
                : null
              }
            </Col>
          </Row>
          <Col className="text-center" lg="12" md="12">
                <Link to={`/noticias`}>
                  <Button
                    className="btn-round mr-1"
                    color="danger"
                    outline
                  > + Notícias
                          </Button>
                </Link>
              </Col>
        </Container>
      </div>
    </>
  );
}

export default NoticiaPage;
