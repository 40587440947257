import React from "react";

// reactstrap components
import { Container, Button } from "reactstrap";
import { Link } from "react-router-dom";
import * as api from '../../services/api'
// core components

function LandingPageHeader() {

  const [secoes, setSecoes] = React.useState([]);

  let pageHeader = React.createRef();

  React.useEffect(() => {
    api.getSecoes()
      .then((result) => {
        setSecoes(result)
      })

    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        if (pageHeader.current != null) {
          pageHeader.current.style.transform =
            "translate3d(0," + windowScrollTop + "px,0)";

        }
      };
      window.addEventListener("scroll", updateScroll);

      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }


  }, []);

  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + require("assets/img/daniel-olahh.jpg") + ")"
        }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="filter" />
        <Container>
          <div className="motto text-center">
            <h1 style={{ marginTop: "180px" }} >Perícias e Investigações  Contábeis</h1>
            <h3>Realizamos os serviços contratados com excelência e em respeito as normas contábeis e decisões judiciais.</h3>
            <br />
            {
              secoes.map((item, i) => {
                if (item.funcao === "Slider") {
                  return <Link to={`/secao/${item.chave}`} key={i}>
                    <Button
                      className="btn-round"
                      color="neutral"
                      type="button"
                      outline>
                      {item.descricaomenu}
                    </Button>
                  </Link>
                } else return null
              })
            }
          </div>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
